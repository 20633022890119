import React from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'fast-deep-equal'
import _debounce from 'lodash.debounce'
import { Map, NavigationControl, Marker, FullscreenControl } from 'bkoi-gl'
import { bbox } from '@turf/turf'
// import * as turf from '@turf/turf'
import { connect } from 'react-redux'

// Import Types
import { MAP } from '../../../Config/config'
import { createGeofence } from '../../../Utils/mapUtils'

class CFMapGL extends React.PureComponent {
  state = {
    container: 'map-' + Math.floor(Math.random() * 1000),
    center: [90.3938010872331, 23.821600277500405],
    zoom: 9,
    map: null,
    renderedMarkers: [],
    geojsonSourceId: 'geojson-source',
    geojsonLabelSourceId: 'geojson-label-source',
    geojsonLayerId: 'geojson-layer',
    renderedGeojson: null
  }

  componentDidMount() {
    // Create Map Instance
    this._createMap()
  }

  componentDidUpdate(prevProps, prevState) {
    const { markerData, geojsonData } = this.props
    const { map } = this.state

    this._removeGeojson()

    // If map changes in state
    if (prevState.map !== map || !_isEqual(prevProps.geojsonData, geojsonData)) {
      // Render Markers
      if(prevState.map !== map){
        
        this._renderMarkers(markerData)
      }
      this._renderGeojson(geojsonData)
      // this._renderGeojsonOnLoad(geojsonData)
    }

    // If Geojson Data Changes
    // if (!_isEqual(prevProps.geojsonData, geojsonData)) {
    //   // Render Geojson Layer
    //   // this._renderGeojson(geojsonData, { fitBounds: !prevProps.geojsonData && geojsonData })
    //   this._renderGeojson(geojsonData)
    // }

    // If Marker Data Changes
    if (!_isEqual(prevProps.markerData, markerData)) {
      // Render Markers
      this._renderMarkers(markerData)
    }
  }

  componentWillUnmount() {
    // Destroy Map Instance
    this._destroyMap()
  }


  // Fit Bounds
  _fitBounds = (data, options = { dataType: 'row-object' }) => {
    const { map } = this.state
    let ifFitBounds = true

    let geoJson = null
    if (options?.dataType === 'geojson') {
      geoJson = data

    } else {
      // To GeoJSON
      geoJson = {
        type: 'FeatureCollection',
        features: data.map(d => {
          if (d.recentOperation) {
            ifFitBounds = false
          }

          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [d.longitude, d.latitude]
            }
          }
        })
      }
    }

    // Fit Bounds
    if (ifFitBounds) {
      // Bounding Box
      const _bbox = bbox(geoJson)

      map.fitBounds(_bbox, { padding: 64, maxZoom: 18, animate: false })
    }
  }


  // Create Map
  _createMap = () => {
    const { container, center, zoom } = this.state
    const { initialZoom } = this.props

    const map = new Map({
      container,
      center,
      zoom: initialZoom ? initialZoom : zoom,
      style: MAP.STYLE,
      accessToken: window?._env_?.REACT_APP_VERIFY_API_KEY,
      attributionControl: false,
    })

    // Add Controls
    map.addControl(new FullscreenControl(), 'top-right')
    map.addControl(new NavigationControl(), 'top-left')


    // Map Resize Event
    this._handleMapResize(map)

    // Disable Double Click Zoom
    map.doubleClickZoom.disable()

    this.setState({ map })
  }

  // Destroy Map
  _destroyMap = () => {
    const { map } = this.state

    // Remove Map Instance
    if (map) {
      map.remove()
      this.setState({ map: null, renderedMarkers: [] })
    }
  }

  // Render Markers
  _renderMarkers = (markerData, options = { fitBounds: true }) => {
    const { map } = this.state

    // Remove Existing Markers
    this._removeMarkers()

    if (!markerData || markerData.length <= 0) {
      return
    }

    // Create Markers
    const renderedMarkers = []
    markerData.forEach(m => {
      const marker = this._createMarker(m)
      if (marker && marker.marker) {
        marker.marker.addTo(map)

        // Show Popup Initially
        // marker.marker._popup.addTo(map)

        renderedMarkers.push(marker)

        // Map Fly To Marker
        map.flyTo({
          center: [marker.data.longitude, marker.data.latitude],
          essential: true,
          // zoom: 11.5,
        })
      }
    })

    this.setState({ renderedMarkers })
  }

  _getRelaventTripMarker = (markerData) => {

    if (markerData?.isVerifier) {
      /***** VERIFIER MARKER ****/
      const verifierMarker = document.createElement('div')
      verifierMarker.className = 'verifierMarker'

      return verifierMarker
    }
    // for end location point
    if (markerData?.tripEndTime && markerData?.isEndLocation) {
      /***** END POINT MARKER ****/
      const endMarker = document.createElement('div')
      endMarker.className = 'endMarker'

      return endMarker
    }


    /**********************
    ***** HOME MARKER ****
    **********************/
    // Create created home Marker
    const createdHomeMarker = document.createElement('div')
    createdHomeMarker.className = 'createdHomeMarker'
    // Create allocated home Marker
    const allocatedHomeMarker = document.createElement('div')
    allocatedHomeMarker.className = 'allocatedHomeMarker'
    // Create onGoing home Marker
    const onGoingHomeMarker = document.createElement('div')
    onGoingHomeMarker.className = 'onGoingHomeMarker'
    // Create finish home Marker
    const holdHomeMarker = document.createElement('div')
    holdHomeMarker.className = 'holdHomeMarker'
    // Create finish home Marker
    const finishedHomeMarker = document.createElement('div')
    finishedHomeMarker.className = 'finishedHomeMarker'
    // Create finish home Marker
    const canceledHomeMarker = document.createElement('div')
    canceledHomeMarker.className = 'canceledHomeMarker'
    // Create finish home Marker
    const inactiveHomeMarker = document.createElement('div')
    inactiveHomeMarker.className = 'inactiveHomeMarker'


    /**********************
     ***** OFFICE MARKER ****
     **********************/
    // Create finish office Marker
    const createdOfficeMarker = document.createElement('div')
    createdOfficeMarker.className = 'createdOfficeMarker'
    // Create finish office Marker
    const allocatedOfficeMarker = document.createElement('div')
    allocatedOfficeMarker.className = 'allocatedOfficeMarker'
    // Create finish office Marker
    const onGoingOfficeMarker = document.createElement('div')
    onGoingOfficeMarker.className = 'onGoingOfficeMarker'
    // Create finish office Marker
    const holdOfficeMarker = document.createElement('div')
    holdOfficeMarker.className = 'holdOfficeMarker'
    // Create finish office Marker
    const finishedOfficeMarker = document.createElement('div')
    finishedOfficeMarker.className = 'finishedOfficeMarker'
    // Create canceled office Marker
    const canceledOfficeMarker = document.createElement('div')
    canceledOfficeMarker.className = 'canceledOfficeMarker'
    // Create inactive office Marker
    const inactiveOfficeMarker = document.createElement('div')
    inactiveOfficeMarker.className = 'inactiveOfficeMarker'

    /**********************
     ***** BANK MARKER ****
     **********************/
    // Create finish bank Marker
    const createdBankMarker = document.createElement('div')
    createdBankMarker.className = 'createdBankMarker'
    // Create finish bank Marker
    const allocatedBankMarker = document.createElement('div')
    allocatedBankMarker.className = 'allocatedBankMarker'
    // Create finish bank Marker
    const onGoingBankMarker = document.createElement('div')
    onGoingBankMarker.className = 'onGoingBankMarker'
    // Create finish bank Marker
    const holdBankMarker = document.createElement('div')
    holdBankMarker.className = 'holdBankMarker'
    // Create finish bank Marker
    const finishedBankMarker = document.createElement('div')
    finishedBankMarker.className = 'finishedBankMarker'
    // Create finish bank Marker
    const canceledBankMarker = document.createElement('div')
    canceledBankMarker.className = 'canceledBankMarker'
    // Create finish bank Marker
    const inactiveBankMarker = document.createElement('div')
    inactiveBankMarker.className = 'inactiveBankMarker'


    /**********************
     ***** CAR QUATATION MARKER ****
     **********************/
    // Create finish bank Marker
    const createdCarQuatationMarker = document.createElement('div')
    createdCarQuatationMarker.className = 'createdCarQuatationMarker'
    // Create finish bank Marker
    const allocatedCarQuatationMarker = document.createElement('div')
    allocatedCarQuatationMarker.className = 'allocatedCarQuatationMarker'
    // Create finish bank Marker
    const onGoingCarQuatationMarker = document.createElement('div')
    onGoingCarQuatationMarker.className = 'onGoingCarQuatationMarker'
    // Create finish bank Marker
    const holdCarQuatationMarker = document.createElement('div')
    holdCarQuatationMarker.className = 'holdCarQuatationMarker'
    // Create finish bank Marker
    const finishedCarQuatationMarker = document.createElement('div')
    finishedCarQuatationMarker.className = 'finishedCarQuatationMarker'
    // Create finish bank Marker
    const canceledCarQuatationMarker = document.createElement('div')
    canceledCarQuatationMarker.className = 'canceledCarQuatationMarker'
    // Create finish bank Marker
    const inactiveCarQuatationMarker = document.createElement('div')
    inactiveCarQuatationMarker.className = 'inactiveCarQuatationMarker'


    /***********************
     *** DOCUMENT MARKER ***
     ***********************/
    // Create finish document Marker
    const createdDocumentMarker = document.createElement('div')
    createdDocumentMarker.className = 'createdDocumentMarker'
    // Create finish document Marker
    const allocatedDocumentMarker = document.createElement('div')
    allocatedDocumentMarker.className = 'allocatedDocumentMarker'
    // Create finish document Marker
    const onGoingDocumentMarker = document.createElement('div')
    onGoingDocumentMarker.className = 'onGoingDocumentMarker'
    // Create finish document Marker
    const holdDocumentMarker = document.createElement('div')
    holdDocumentMarker.className = 'holdDocumentMarker'
    // Create finish document Marker
    const finishedDocumentMarker = document.createElement('div')
    finishedDocumentMarker.className = 'finishedDocumentMarker'
    // Create finish document Marker
    const canceledDocumentMarker = document.createElement('div')
    canceledDocumentMarker.className = 'canceledDocumentMarker'
    // Create finish document Marker
    const inactiveDocumentMarker = document.createElement('div')
    inactiveDocumentMarker.className = 'inactiveDocumentMarker'


    /***********************
     ***** RENTAL MARKER ***
     **********************/
    // Create finish rental Marker
    const createdRentalMarker = document.createElement('div')
    createdRentalMarker.className = 'createdRentalMarker'
    // Create finish rental Marker
    const allocatedRentalMarker = document.createElement('div')
    allocatedRentalMarker.className = 'allocatedRentalMarker'
    // Create finish rental Marker
    const onGoingRentalMarker = document.createElement('div')
    onGoingRentalMarker.className = 'onGoingRentalMarker'
    // Create finish rental Marker
    const holdRentalMarker = document.createElement('div')
    holdRentalMarker.className = 'holdRentalMarker'
    // Create finish rental Marker
    const finishedRentalMarker = document.createElement('div')
    finishedRentalMarker.className = 'finishedRentalMarker'
    // Create finish rental Marker
    const canceledRentalMarker = document.createElement('div')
    canceledRentalMarker.className = 'canceledRentalMarker'
    // Create finish rental Marker
    const inactiveRentalMarker = document.createElement('div')
    inactiveRentalMarker.className = 'inactiveRentalMarker'


    /**********************
     ***** COMMON MARKER ****
     **********************/
    // Create finish common finish Marker
    const commonMarker = document.createElement('div')
    commonMarker.className = 'commonMarker'



    switch (markerData?.status) {
      case 1:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return createdHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return createdOfficeMarker
          case ('BANK'):
            return createdBankMarker
          case ('DOCUMENT'):
            return createdDocumentMarker
          case ('RENTAL'):
            return createdRentalMarker
          case ('CAR_QUATATION'):
            return createdCarQuatationMarker
          default:
            return commonMarker
        }
      case 2:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return allocatedHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return allocatedOfficeMarker
          case ('BANK'):
            return allocatedBankMarker
          case ('DOCUMENT'):
            return allocatedDocumentMarker
          case ('RENTAL'):
            return allocatedRentalMarker
            case ('CAR_QUATATION'):
            return allocatedCarQuatationMarker
          default:
            return commonMarker
        }
      case 3:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return onGoingHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return onGoingOfficeMarker
          case ('BANK'):
            return onGoingBankMarker
          case ('DOCUMENT'):
            return onGoingDocumentMarker
          case ('RENTAL'):
            return onGoingRentalMarker
          case ('CAR_QUATATION'):
            return onGoingCarQuatationMarker
          default:
            return commonMarker
        }
      case 4:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return holdHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return holdOfficeMarker
          case ('BANK'):
            return holdBankMarker
          case ('DOCUMENT'):
            return holdDocumentMarker
          case ('RENTAL'):
            return holdRentalMarker
          case ('CAR_QUATATION'):
            return holdCarQuatationMarker
          default:
            return commonMarker
        }
      case 5:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return finishedHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return finishedOfficeMarker
          case ('BANK'):
            return finishedBankMarker
          case ('DOCUMENT'):
            return finishedDocumentMarker
          case ('RENTAL'):
            return finishedRentalMarker
          case ('CAR_QUATATION'):
            return finishedCarQuatationMarker
          default:
            return commonMarker
        }
      case 6:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return canceledHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return canceledOfficeMarker
          case ('BANK'):
            return canceledBankMarker
          case ('DOCUMENT'):
            return canceledDocumentMarker
          case ('RENTAL'):
            return canceledRentalMarker
          case ('CAR_QUATATION'):
            return canceledCarQuatationMarker
          default:
            return commonMarker
        }
      case 7:
        switch (markerData?.tripType) {
          case 'HOUSE':
          case 'GUARANTOR_HOUSE':
            return inactiveHomeMarker
          case 'OFFICE':
          case 'GUARANTOR_OFFICE':
            return inactiveOfficeMarker
          case ('BANK'):
            return inactiveBankMarker
          case ('DOCUMENT'):
            return inactiveDocumentMarker
          case ('RENTAL'):
            return inactiveRentalMarker
          case ('CAR_QUATATION'):
            return finishedCarQuatationMarker
          default:
            return commonMarker
        }
      default:
        return null
    }


  }



  // Remove Markers
  _removeMarkers = () => {
    const { renderedMarkers } = this.state

    if (!renderedMarkers || renderedMarkers.length <= 0) {
      return
    }

    renderedMarkers.forEach(m => {
      if (m && m.marker) {
        m.marker.remove()
      }
    })

    this.setState({ renderedMarkers: [] })
  }

  // Create marker instance
  _createMarker = data => {

    if (!data) {
      return null
    }

    if (data.longitude === null || data.longitude === undefined || data.longitude === '' || data.latitude === null || data.latitude === undefined || data.latitude === '') {
      return null
    }

    const temp = this._generateMarker(data)
    return temp
  }

  // Create marker
  _generateMarker = (data) => {
    // const { defaultMarker } = this.props

    const lngLat = [data.longitude, data.latitude]


    /**********************
     ***** COMMON MARKER ****
     **********************/
    // Create finish common finish Marker
    const commonMarker = document.createElement('div')
    commonMarker.className = 'commonMarker'

    /***************************
     ***** END POINT MARKER ****
     **************************/
    // Create task finish location Marker
    const endMarker = document.createElement('div')
    endMarker.className = 'endMarker'

    /**************************
     ***** VERIFIER MARKER ****
     *************************/
    // Create task finish location Marker
    const verifierMarker = document.createElement('div')
    verifierMarker.className = 'verifierMarker'


    // const customPopup = this._createCustomPopup(data)

    // Create Popup
    // const popup = new Popup({
    //   focusAfterOpen: false,
    //   maxWidth: '320px',
    //   offset: data?.isEndLocation ? 15 : 30,
    // })
    //   .setDOMContent(customPopup)

    //getting marker for trip, endpoint or verifier
    const tripMarker = this._getRelaventTripMarker(data)

  

    // Creating Marker
    const marker = new Marker({
      element: tripMarker,
      color: this._getMarkerColor(data),
      draggable: this._checkDraggableMarker(data),
      scale: '1',
      anchor: (data?.tripType || data?.isVerifier) ? 'bottom' : 'center', // data?.tripType for multipla trip with end location
    })
      .setLngLat(lngLat)
      // .setPopup(defaultMarker ? null : popup)
      marker._element.id = data?.trip_id ? data.trip_id : ''


    marker.on('dragend', () => {
      const { map, geojsonLabelSourceId } = this.state
      const { getDragEndLngLat, adjustZoomOnMarkerDrag, geojsonData } = this.props
      const { lat, lng } = marker.getLngLat()
      // saving the trip id to marker element so that in futre trip can be foun out from marker.
      const dataID = marker._element?.id

      // Check if lat and lng is valid and number
      if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
        // Check if getDragEndLngLat is defined
        if (getDragEndLngLat) {
          // Get Drag End Lng Lat
          getDragEndLngLat(lng, lat)
        }
        
        let dataIndex;
        geojsonData?.features.forEach((data,index) => {
          // finding the trip by trip_id and !isVerifier property because both has same trip_id
          if( data?.properties?.draggable && data?.properties?.trip_id === Number(dataID) && !data?.properties?.isVerifier){
            dataIndex = index
            data.geometry.coordinates = [lng, lat]
            data.properties.latitude = lat
            data.properties.longitude = lng
          }
        })

        // setting the new coordinate to the dragged marker geojson text label
        map.getSource(`${geojsonLabelSourceId}-${Number(dataIndex)}`).setData(geojsonData);

        map.flyTo({
          center: [lng, lat],
          essential: true,
          zoom: adjustZoomOnMarkerDrag ? 12 : map.getZoom()
        })
      }

    })

    return { marker, data }
  }

  // Get Draggable Marker
  _checkDraggableMarker = (data) => {
    // Check if data is null
    if (!data) {
      return false
    }

    // Check if data has not draggable property
    if (!data.draggable) {
      return false
    }

    // Check if data has draggable property
    if (data?.draggable && data.draggable === true) {
      return true
    }
  }

  // Get Marker Color
  _getMarkerColor = (data) => {
    // Check if data is null
    if (!data) {
      return ''
    }
    // check if it is the marker of end location 
    if (data?.isEndLocation === true) {
      return 'black'
    }

    // Check if data has concern
    else if (data?.concern) {
      // Check if concern includes Applicant
      if (data?.concern.includes('Applicant')) {
        return 'green'
      }

      // Check if concern includes Guarantor
      if (data?.concern.includes('Guarantor')) {
        return 'blue'
      }

      // Check if concern includes 'Exact'
      if (data?.concern.includes('Exact')) {
        return '#323f4d'
      }

    }
    return ''
  }

  // Create Custom Popup
  _createCustomPopup = (data) => {

    const markerTooltipWrapper = document.createElement('div')

    if (data?.isEndLocation === true) {
      // if(data?.isAddressOfEndedTrip === true){
      const endDataContainer = document.createElement('div')
      // endDataContainer.style.display = 'flex'

      const endTripTag = document.createElement('div')
      const endLabelTag = document.createElement('div')
      const endTimeTag = document.createElement('div')

      endTripTag.style.display = 'flex'
      endLabelTag.style.display = 'flex'
      endTimeTag.style.display = 'flex'

      const endTripPtag = document.createElement('p')
      const endLabelPtag = document.createElement('p')
      const endTimePtag = document.createElement('p')

      endTripPtag.setAttribute('class', 'customPopupTitle')
      endLabelPtag.setAttribute('class', 'customPopupTitle')
      endTimePtag.setAttribute('class', 'customPopupTitle')

      const endTripPrefix = document.createTextNode('Trip ID: ')
      const endLabelPrefix = document.createTextNode('Verified By: ')
      const endTimePrefix = document.createTextNode('Ended: ')

      const endTripText = document.createTextNode(data?.trip_id ?? ' N/A')
      const endLabelText = document.createTextNode(data?.verifierName ?? ' N/A')
      const endTimeText = document.createTextNode(data?.tripEndTime ?? ' N/A')

      endTripPtag.appendChild(endTripPrefix)
      endLabelPtag.appendChild(endLabelPrefix)
      endTimePtag.appendChild(endTimePrefix)

      endTripTag.appendChild(endTripPtag)
      endTripTag.appendChild(endTripText)
      endLabelTag.appendChild(endLabelPtag)
      endLabelTag.appendChild(endLabelText)
      endTimeTag.appendChild(endTimePtag)
      endTimeTag.appendChild(endTimeText)

      endDataContainer.appendChild(endTripTag)
      endDataContainer.appendChild(endLabelTag)
      endDataContainer.appendChild(endTimeTag)



      markerTooltipWrapper.setAttribute('id', 'custom-popup')
      markerTooltipWrapper.appendChild(endDataContainer)

      return markerTooltipWrapper
    }
    else if (data?.isVerifier) {

      // const tripID = document.createTextNode( data?.trip_id ?? ' N/A' )    
      const nameText = document.createTextNode(data?.label ?? ' N/A')

      // const endLabelTag = document.createElement('div')
      // endLabelTag.style.display = 'flex'
      const nameEl = document.createElement('div')
      nameEl.style.display = 'flex'

      // const endLabelPtag = document.createElement('p')
      const nameTitleEl = document.createElement('p')

      // endLabelPtag.setAttribute('class', 'customPopupTitle')
      nameTitleEl.setAttribute('class', 'customPopupTitle')

      // const tripTitle = document.createTextNode('Trip ID: ')
      const nameTitle = document.createTextNode('Verifier: ')

      // endLabelPtag.appendChild(tripTitle)
      nameTitleEl.appendChild(nameTitle)

      // endLabelTag.appendChild(endLabelPtag)
      // endLabelTag.appendChild(tripID)  

      nameEl.appendChild(nameTitleEl)
      nameEl.appendChild(nameText)

      markerTooltipWrapper.setAttribute('id', 'custom-popup')
      // markerTooltipWrapper.appendChild(endLabelTag)
      markerTooltipWrapper.appendChild(nameEl)

      return markerTooltipWrapper
    }


    const tripID = document.createTextNode(data?.trip_id ?? ' N/A')
    // const nameText = document.createTextNode( data?.label ?? ' N/A' )    

    const endLabelTag = document.createElement('div')
    endLabelTag.style.display = 'flex'
    // const nameEl = document.createElement('div')
    // nameEl.style.display = 'flex'

    const endLabelPtag = document.createElement('p')
    // const nameTitleEl = document.createElement('p')

    endLabelPtag.setAttribute('class', 'customPopupTitle')
    // nameTitleEl.setAttribute('class', 'customPopupTitle')

    const tripTitle = document.createTextNode('Trip ID: ')
    // const nameTitle = document.createTextNode('Address: ')

    endLabelPtag.appendChild(tripTitle)
    // nameTitleEl.appendChild(nameTitle)

    endLabelTag.appendChild(endLabelPtag)
    endLabelTag.appendChild(tripID)

    // nameEl.appendChild(nameTitleEl)
    // nameEl.appendChild(nameText)    

    markerTooltipWrapper.setAttribute('id', 'custom-popup')
    markerTooltipWrapper.appendChild(endLabelTag)
    // markerTooltipWrapper.appendChild(nameEl)
    return markerTooltipWrapper
  }

  // // Fit Bounds
  // _fitBounds = (data, options={ dataType: 'row-object' }) => {
  //   const { map } = this.state
  //   let ifFitBounds = true

  //   let geoJson = null
  //   if(options?.dataType === 'geojson') {
  //     geoJson = data

  //   } else {
  //     // To GeoJSON
  //     geoJson = {
  //       type: 'FeatureCollection',
  //       features: data.map(d => {
  //         if(d.recentOperation) {
  //           ifFitBounds = false
  //         }

  //         return {
  //           type: 'Feature',
  //           geometry: {
  //             type: 'Point',
  //             coordinates: [ d.longitude, d.latitude ]
  //           }
  //         }
  //       })
  //     }
  //   }

  //   // Fit Bounds
  //   if(ifFitBounds) {
  //     // Bounding Box
  //     const _bbox = bbox(geoJson)

  //     map.fitBounds(_bbox, { padding: 64, maxZoom: 18, animate: false })
  //   }
  // }


  // Render Geojson
  _renderGeojson = (geojsonData, options = { fitBounds: true }) => {
    const { map, geojsonSourceId, geojsonLayerId, geojsonLabelSourceId } = this.state

   
    // Remove Existing Geojson
    this._removeGeojson()

    if (!geojsonData?.features?.length) {
      return
    }


    map.on('load', () => {

      geojsonData?.features.forEach((f, i) => {
        const markerFence = createGeofence(f.geometry.coordinates)

        if (map.getLayer(`${geojsonLayerId}-${i}`)) {
          map.removeLayer(`${geojsonLayerId}-${i}`)
        }
        if (map.getLayer(`poi-labels-${i}`)) {
          map.removeLayer(`poi-labels-${i}`)
        }
        if (map.getSource(`${geojsonSourceId}-${i}`)) {
          map.removeSource(`${geojsonSourceId}-${i}`)
        }
        if (map.getSource(`${geojsonLabelSourceId}-${i}`)) {
          map.removeSource(`${geojsonLabelSourceId}-${i}`)
        }
        
        // Add marker label To Map
        map.addSource(`${geojsonLabelSourceId}-${i}`, {
          type: 'geojson',
          data: f
        })
        
        // Add Geojson Source To Map
        map.addSource(`${geojsonSourceId}-${i}`, {
          type: 'geojson',
          data: markerFence
        })


        if(f?.geometry?.type === 'Point') { 
          
          // creating text label
          map.addLayer({
            'id': `poi-labels-${i}`,
            'type': 'symbol',
            'source': `${geojsonLabelSourceId}-${i}`,
            'icon-allow-overlap': 'true',
            'layout': {
              'text-field': ['get', 'description'],
              'text-allow-overlap': true,
              'text-ignore-placement': true,
              'text-variable-anchor': f?.properties?.isEndLocation ? ['left'] : ['top'],
              'text-radial-offset': 0.5,
              'text-justify': 'auto',
              'text-line-height': 1,
              'text-size': 13,
              'text-font': ['Open Sans Regular','Arial Unicode MS Regular'],
              'text-max-width': 70
              // 'icon-image': ['get', 'icon']
            }
          });

          // checking is this data should have fence 
          if(f?.properties?.haveFence){
            // creating fence
            map.addLayer({
              'id': `${geojsonLayerId}-${i}`,
              'type': 'fill',
              'source': `${geojsonSourceId}-${i}`,
              'layout': {
                'visibility': 'visible'
              },
              'paint': {
                // 'fill-color': f?.properties?.fillColor ?? '#3A0E89',
                'fill-color': '#dd4242',
                // 'fill-opacity': f?.properties?.opacity ?? 0.5,
                'fill-opacity': 0.25,
                "fill-outline-color": "black"
              },
              'filter': ['==', '$type', 'Polygon']
            })
          }

        }

        // if(f?.geometry?.type === 'Point') { 
        //   // Add Polygon Layer
        //   map.addLayer({
        //       'id': `${ geojsonLayerId }-${ i }`,
        //       'type': 'circle',
        //       'source': `${ geojsonSourceId }-${ i }`,
        //       'layout': {
        //         'visibility': 'visible'  
        //         },
        //       "paint": {
        //         "circle-radius": {
        //           'base': 1.75,
        //           'stops': [
        //               [12, 2],
        //               [22, 180]
        //             ]
        //           },
        //         // "circle-unit": 'meteres',
        //         "circle-opacity": .4,
        //         "circle-stroke-width": 1.5,
        //         "circle-stroke-color": "#0059FF",
        //         "circle-stroke-opacity": 1,
        //     },
        //     "filter": ["==", '$type', "Point"],
        //   })
        // }

        // adding fence 
        //   if(f?.geometry?.type === 'Point') { 
        //    // Add Polygon Layer 
        //    map.addLayer({
        //       'id': `${ geojsonLayerId }-${ i }-Point`,
        //       'source': `${ geojsonSourceId }-${ i }`,
        //        'type': "fill",
        //        'paint': {
        //          "fill-color": "#dd4242",
        //          "fill-opacity": 0.4,
        //          "fill-outline-color": "yellow"
        //        }
        //    }) 
        //  }


        // if(f?.geometry?.type === 'LineString') {
        //   // Add LineString Layer
        //   map.addLayer({
        //     'id': `${ geojsonLayerId }-${ i }`,
        //     'type': 'line',
        //     'source': `${ geojsonSourceId }-${ i }`,
        //     'layout': {
        //       'visibility': 'visible',
        //       'line-join': 'round',
        //       'line-cap': 'round'
        //     },
        //     'paint': {
        //       'line-color': f?.properties?.lineColor ?? '#008000',
        //       'line-width': f?.properties?.lineWidth ?? 4
        //     },
        //     'filter': [ '==', '$type', 'LineString' ]
        //   })
        // }

        if (f?.geometry?.type === 'Point') {
          // Add Polygon Layer
          // map.addLayer({
          //   'id': `${geojsonLayerId}-${i}`,
          //   'type': 'fill',
          //   'source': `${geojsonSourceId}-${i}`,
          //   'layout': {
          //     'visibility': 'visible'
          //   },
          //   'paint': {
          //     // 'fill-color': f?.properties?.fillColor ?? '#3A0E89',
          //     'fill-color': '#dd4242',
          //     // 'fill-opacity': f?.properties?.opacity ?? 0.5,
          //     'fill-opacity': 0.25,
          //     "fill-outline-color": "black"
          //   },
          //   'filter': ['==', '$type', 'Polygon']
          // })

          // Add Polygon Outline Layer
          // map.addLayer({
          //     'id': `${ geojsonLayerId }-${ i }-outline`,
          //     'type': 'line',
          //     'source': `${ geojsonSourceId }-${ i }-update`,
          //     'layout': {
          //         'visibility': 'visible',
          //         'line-join': 'round',
          //         'line-cap': 'round'
          //     },
          //     'paint': {
          //         'line-color': f?.properties?.lineColor ?? '#008000',
          //         'line-width': f?.properties?.lineWidth ?? 2
          //     }
          // })
        }
      })

      // Fit Map Bounds
      // if(options.fitBounds) {
      //   this._fitBounds(geojsonData, { dataType: 'geojson' })
      // }
    })
    this.setState({ renderedGeojson: geojsonData })
  }


  // Render GeoJSON without map on load
  _renderGeojsonOnLoad = (geojsonData, options = { fitBounds: true }) => {
    const { map, geojsonSourceId } = this.state

    // Remove Existing Geojson
    this._removeGeojson()

    if (!geojsonData?.features?.length) {
      return
    }

    map.on('load', () => {
      geojsonData?.features.forEach((f, i) => {
        const markerFence = createGeofence(f.geometry.coordinates)

        // Add Geojson Source To Map
        // map.addSource(`${geojsonSourceId}-${i}`, {
        //   type: 'geojson',
        //   data: markerFence
        // })

        // adding fence 
        //  if(f?.geometry?.type === 'Point') {
        //   // Add Polygon Layer
        //   map.addLayer({
        //       'id': `${ geojsonLayerId }-${ i }`,
        //       // 'type': 'circle',
        //       'source': `${ geojsonSourceId }-${ i }`,
        //       'type': "fill",
        //       'paint': {
        //         "fill-color": "#dd4242",
        //         "fill-opacity": 0.4,
        //         "fill-outline-color": "yellow"
        //       }
        //   })
        // }

        // if(f?.geometry?.type === 'LineString') {
        //   // Add LineString Layer
        //   map.addLayer({
        //     'id': `${ geojsonLayerId }-${ i }`,
        //     'type': 'line',
        //     'source': `${ geojsonSourceId }-${ i }`,
        //     'layout': {
        //       'visibility': 'visible',
        //       'line-join': 'round',
        //       'line-cap': 'round'
        //     },
        //     'paint': {
        //       'line-color': f?.properties?.lineColor ?? '#008000',
        //       'line-width': f?.properties?.lineWidth ?? 4
        //     },
        //     'filter': [ '==', '$type', 'LineString' ]
        //   })
        // }

        // if(f?.geometry?.type === 'Polygon') {
        //     // Add Polygon Layer
        //   map.addLayer({
        //       'id': `${ geojsonLayerId }-${ i }-layer`,
        //       'type': 'fill',
        //       'source': `${ geojsonSourceId }-${ i }`,
        //       'layout': {
        //           'visibility': 'visible'
        //       },
        //       'paint': {
        //           'fill-color': f?.properties?.fillColor ?? '#3A0E89',
        //           'fill-opacity': f?.properties?.opacity ?? 0.1
        //       },
        //       'filter': [ '==', '$type', 'Polygon' ]
        //   })

        //   // Add Polygon Outline Layer
        //   map.addLayer({
        //       'id': `${ geojsonLayerId }-${ i }-update-outline`,
        //       'type': 'line',
        //       'source': `${ geojsonSourceId }-${ i }`,
        //       'layout': {
        //           'visibility': 'visible',
        //           'line-join': 'round',
        //           'line-cap': 'round'
        //       },
        //       'paint': {
        //           'line-color': f?.properties?.lineColor ?? '#008000',
        //           'line-width': f?.properties?.lineWidth ?? 2
        //       }
        //   })
        // }
      })

      // Fit Map Bounds
      // if(options.fitBounds) {
      //   this._fitBounds(geojsonData, { dataType: 'geojson' })
      // }
    })

    this.setState({ renderedGeojson: geojsonData })
  }

  // Remove Geojson
  _removeGeojson = () => {
    const { map, geojsonSourceId, geojsonLayerId, renderedGeojson } = this.state
    const {geojsonData} = this.props

    if (!renderedGeojson) {
      return
    }

    // Remove Geojson Layer
    geojsonData?.features.forEach((f, i) => {
      if (map.getLayer(`${geojsonLayerId}-${i}`)) {
        map.removeLayer(`${geojsonLayerId}-${i}`)
      }
      // if(map.getLayer(`${ geojsonLayerId }-${ i }-polygon`)) {    
      //   map.removeLayer(`${ geojsonLayerId }-${ i }-polygon`)
      // }
      if (map.getLayer(`${geojsonLayerId}-${i}-layer`)) {
        map.removeLayer(`${geojsonLayerId}-${i}-layer`)
      }

      if (map.getLayer(`${geojsonLayerId}-${i}-outline`)) {
        map.removeLayer(`${geojsonLayerId}-${i}-outline`)
      }
      if (map.getLayer(`${geojsonLayerId}-${i}-update-outline`)) {
        map.removeLayer(`${geojsonLayerId}-${i}-update-outline`)
      }
    })

    // Remove Geojson Source
    renderedGeojson.features.forEach((f, i) => {
      if (map.getSource(`${geojsonSourceId}-${i}`)) {
        map.removeSource(`${geojsonSourceId}-${i}`)
      }
      if (map.getSource(`${geojsonSourceId}-${i}-update`)) {
        map.removeSource(`${geojsonSourceId}-${i}-update`)
      }
    })

    this.setState({ renderedGeojson: null })
  }



  // Handle Map Resize
  _handleMapResize = map => {
    const { container } = this.state

    // Add Event Listeners
    const mapContainerElement = document.getElementById(container)
    if (mapContainerElement) {
      new ResizeObserver(_debounce(() => {
        if (map) {
          map.resize()
        }
      }, 10)).observe(mapContainerElement)
    }
  }

  render() {
    const { container } = this.state
    const { mapHeight } = this.props

    return (
      <div id={container} style={mapHeight ? { ...containerStyles, minHeight: mapHeight } : containerStyles} />
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  // height: '300px',
  overflow: 'hidden',
  border: '1px solid #dcdcdc',
  borderRadius: '4px',
  flex: 1
}

// Prop Types
CFMapGL.propTypes = {
  markerData: PropTypes.array,
  defaultMarker: PropTypes.bool,
  getDragEndLngLat: PropTypes.func,
  mapHeight: PropTypes.string,
  initialZoom: PropTypes.number,
  adjustZoomOnMarkerDrag: PropTypes.bool,
}

CFMapGL.defaultProps = {
  markerData: [],
  defaultMarker: false,
  getDragEndLngLat: () => null,
  mapHeight: '',
  initialZoom: 9, // initial zoom level of map
  adjustZoomOnMarkerDrag: false, // zoom of flyto effect after dragging marker
}

// Map State To Props
const mapStateToProps = state => ({
  // markerDataTripUpdate: state.common.markerDataTripUpdate,
  // selectedMultipleTripToUpdate: state.common.selectedMultipleTripToUpdate,
})

export default connect(mapStateToProps, null)(CFMapGL)